<template>
  <div>
    <!-- ローディング画面 -->
    <LoadingScreen v-if="isLoading" />

    <!-- メイン画面 -->
    <div v-else>
      <!-- 背景画像とナビゲーションを含むコンテナ -->
      <PageNavigation />
      <div class="image-container">
        <img alt="Aozora image" src="@/assets/images/blue_sky01.png" class="background-image" />
        
        <div class="top-main">
          <div class="top-main-inner">
            <div class="vertical-txt">
              <p>青空クリニックは、</p>
              <p>地域の皆さまに寄り添いながら、</p>
              <p>健康と安心をお届けします。</p>
            </div>
          </div>
        </div>
        
        <!-- 画像に要素をオーバーレイする -->
        <div class="overlay-image">
          <PageHeader />
          <ClinicSchedule />
        </div>
      </div>
      
      <div class="media-txt">
        <p>青空クリニックは、</p>
        <p>地域の皆さまに寄り添いながら、</p>
        <p>健康と安心をお届けします。</p>
      </div>

      <!-- お知らせ情報 -->
      <section id="news-list">
        <h2>お知らせ</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="news-container">
          <div v-if="isLoading">お知らせを読み込んでいます...</div>
          <div v-else-if="newsList.length === 0">お知らせはありません。</div>
          <div v-else>
            <NewsItem :newsList="newsList" />
          </div>
        </div>
      </section>

      <!-- 診療内容 -->
      <section id="treatment-category-list">
        <h2>診療内容</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="content-padding">
          <div class="center">
            <p>当院では内科、皮膚科、泌尿器科など幅広い診療を行っています。</p>
          </div>
          <div class="treatment-box">
            <div @click="navigateToTreatmentList('general_internal_medicine')" class="treatment-category-box">
              <img class="category-icon" src="@/assets/images/内科-100.png" alt="">
              <h4>一般内科</h4>
              <div class="category-description">
                <p>内臓疾患全般を幅広く診察・治療する診療科です</p>
              </div>
            </div>
            <div @click="navigateToTreatmentList('dermatology')" class="treatment-category-box">
              <img class="category-icon" src="@/assets/images/皮膚科-100.png" alt="">
              <h4>皮膚科</h4>
              <div class="category-description">
                <p>皮膚や爪、髪、粘膜の疾患を診察・治療する診療科です</p>
              </div>
            </div>
            <div @click="navigateToTreatmentList('urology')" class="treatment-category-box">
              <img class="category-icon" src="@/assets/images/泌尿器科-100.png" alt="">
              <h4>泌尿器科</h4>
              <div class="category-description">
                <p>尿路や生殖器の疾患を診察・治療する診療科です</p>
              </div>
            </div>
            <div @click="navigateToTreatmentList('exam_immunization')" class="treatment-category-box">
              <img class="category-icon" src="@/assets/images/予防接種-100.png" alt="">
              <h4>検査・予防接種</h4>
              <div class="category-description">
                <p>健康状態の確認や病気予防のための医療を行います</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 院内紹介 -->
      <section id="clinic-introduction">
        <h2>院内紹介</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <ClinicIntroduction />
      </section>
      <!-- アクセス情報 -->
      <section id="access"> 
        <h2>アクセス</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="map-container">
          <div class="center">
            <div class="center-block">
              <div class="info">
                <p>〒939-8057 富山県富山市堀141</p>
                <p>TEL: 076-420-4411</p>
              </div>
              <div class="google-map">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d800.2158976472181!2d137.23004163388924!3d36.65373212313783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff791ef31d09e43%3A0xd88e79da506a7d9b!2z6Z2S56m644Kv44Oq44OL44OD44Kv!5e0!3m2!1sja!2sjp!4v1731819996855!5m2!1sja!2sjp&output=embed&disableDefaultUI=true" 
                  width="600" 
                  height="450" 
                  style="border:0;" 
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
              <ClinicSchedule />
            </div>
          </div>
        </div>
      </section>
      <!-- 紹介先提携病院 -->
      <section id="relation-hospitals">
        <h2>紹介先・提携病院</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="content-padding">
          <div class="center">
            <p>当クリニックは、下記医療機関と緊密な診療連携を結んでおり、入院や精密な検査が必要な際には適切なタイミングでのご紹介が可能です。</p>
          </div>
          <div class="center-block">
            <a href="https://www.tch.pref.toyama.jp/">
              富山県立中央病院
            </a>
            <a href="https://www.tch.toyama.toyama.jp/">
              富山市立富山市民病院
            </a>
            <a href="https://www.saiseikai-toyama.jp/">
              富山県済生会富山病院
            </a>
            <a href="https://www.toyama-med.jrc.or.jp/">
              富山赤十字病院
            </a>
          </div>
        </div>
      </section>
      <PageFooter />
    </div>
  </div>
</template>

<script>
import newsService from '@/services/newsService';
import PageHeader from '@/components/PageHeader.vue';
import PageNavigation from '@/components/PageNavigation.vue';
import ClinicSchedule from '@/components/ClinicSchedule.vue';
import ClinicIntroduction from '@/components/ClinicIntroduction.vue';
import NewsItem from '@/components/NewsItem.vue';
import PageFooter from '@/components/PageFooter.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';

export default {
  name: 'HomePage',
  components: {
    PageHeader,
    NewsItem,
    PageNavigation,
    ClinicSchedule,
    ClinicIntroduction,
    PageFooter,
    LoadingScreen, // ローディングコンポーネントを登録
  },
  data() {
    return {
      newsList: [], // BEから取得した全ニュース（空配列で初期化）
      isLoading: true, // ローディング状態
    };
  },
  mounted() {
    this.loadNews();
  },
  methods: {
    async loadNews() {
      try {
        this.newsList = await newsService.getNewsList(); // BEからデータを取得
        this.updateNewsList(this.newsList)
      } catch (error) {
        console.error('お知らせの読み込みに失敗しました:', error);
      } finally {
        this.isLoading = false; // ローディング終了
      }
    },
    updateNewsList(updatedNewsList) {
      // お知らせ情報日付順に更新
      if (Array.isArray(updatedNewsList)) {
        this.newsList = updatedNewsList.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
      } else {
        console.error('無効なデータ形式が渡されました:', updatedNewsList);
      }
    },
    navigateToTreatmentList(treatmentCategory) {
      // 診療内容のページに遷移
      this.$router.push(`/treatment/${treatmentCategory}`);
    }
  }
};
</script>

<style scoped>
/* 画像を含むコンテナ */
.image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* 背景画像 */
.background-image {
  width: 100%;
  height: 100vh;
  background-size: cover; /* 要素全体を覆う */
}

/* ナビゲーションを背景画像上に重ねる */
.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: white;
  padding: 0px;
  text-align: center;
}

#news-list {
  margin-top: 20px;
}

#news-list .news-item {
  margin-bottom: 15px;
}

.news-container{
  margin: 0 10vw;
}
/* `.top-main` スタイル */
.top-main {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}

.media-txt {
  display: none;
}

.top-main-inner {
  position: relative;
  width: 47.2%;
  height: 100%;
}

.vertical-txt {
  position: absolute;
  bottom: 63%;
  right: -35%;
  transform: translate(50%, 50%) scale(0.9);
  font-feature-settings: 'palt' 0;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  opacity: 1;
  color: black;
  font-weight: bold;
  font-size: min(2vw, 25px);
}

.vertical-txt p {
  font-size: 1em;
}

#access .info {
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

#access .info p{
  line-height: 1;
}

.map-container {
  width: 100%;
  /* min-width: 800px; */
  margin: 0 auto; /* センター寄せ */
}

.map-container .center-block{
  /* margin-left: 3vw;  */
  width: 40%;
}

.google-map {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 500px;
  margin-bottom: 3vw;
}

.google-map iframe {
  /* min-width: 500px; */
  border: none; /* 枠線を削除 */
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: calc(100% + (150px * 2));
}

#relation-hospitals a {
    display: block;
    margin-block-end: 2vh;
}
@media (max-width: 768px) {

  .top-main {
    position: static;
    height: auto;
    min-height: 35px;
    padding: 20px;
    color: gray;
  }
  .top-main-inner {
    width: 90%;
  }
  .media-txt {
    display: block;
    text-align: center;
    font-weight: bolder;
    margin: 100px;
  }

  .top-main >>> .vertical-txt {
    display: none;
  }

  .news-container{
    margin: 0 0;
  }

  .map-container .center{
    display: block;
  }

  .map-container .center-block{
    margin-left: 0;
    width: 100%;
  }
}

</style>
