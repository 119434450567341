import axios from 'axios'; // 必要に応じて Fetch API に変更可能
import tokenService from '@/services/tokenService';

// API のベース URL
const BASE_URL = 'https://i3ot0jpysf.execute-api.ap-northeast-1.amazonaws.com/dev/news';

const newsService = {
/**
   * お知らせ情報を登録する
   * @param {Object} newNews 更新データ
   * @returns {Promise<void>} 更新後のお知らせデータ
   */
    async postNews(newNews) {
        if (newNews.title && newNews.content && newNews.date) {
          try { 
            const headers = tokenService.createHeaders()
            // console.log('お知らせ追加内容:', newNews)

            // お知らせをAPIに送信
            const response = await axios.post(`${BASE_URL}`, 
              JSON.stringify({
                title: newNews.title,
                content: newNews.content,
                date: newNews.date
              }), 
              { headers }
            );

            if (response.data.statusCode != 200) {
              console.error('追加に失敗しました:', response.data.body);
              throw new Error('お知らせ情報の追加に失敗しました');              
            }
            // 成功メッセージ
            // console.log('お知らせが追加されました:', response.data);
  
          } catch (error) {
            console.error('お知らせの追加に失敗しました:', error);
            alert('お知らせの追加に失敗しました。再度試してください。');
            throw error;
          }
        } else {
          alert('すべての項目を入力してください。');
          throw new Error('必須項目が入力されていません');
        }
    },

/**
 * お知らせ情報をリスト取得する関数
 * @param {boolean} [admin=false] - 管理者フラグ。trueの場合、詳細データを含むレスポンスを返す。
 * @returns {Promise<Array>} ニュース情報の配列
 */
    async getNewsList(admin = false) {
      let responseBody = [];
      try {
        const response = await axios.get(`${BASE_URL}`);
        
        // デバッグ: レスポンス全体を確認
        // console.log('APIレスポンス:', response.data);

        // bodyが既にオブジェクトならJSON.parse不要
        responseBody = response.data.body;

        if (response.data.statusCode != 200) {
          console.error('取得に失敗しました:', responseBody);
          throw new Error('お知らせ情報のリスト取得に失敗しました');              
        }
        // レスポンスが配列であるか確認
        if (Array.isArray(responseBody)) {
          // 必要なデータを抽出
          return responseBody.map(news => {
              if (admin) {
              // 管理者用データ
              return {
                  news_id: news.news_id,
                  title: news.title,
                  content: news.content,
                  date: news.date,
                  create_info: news.create_info,
                  update_info: news.update_info,
              };
              } else {
              // 一般ユーザー用データ
              return {
                  news_id: news.news_id,
                  title: news.title,
                  date: news.date,
              };
              }
          });
        } else {
          console.error('取得したデータが配列ではありません:', responseBody);
          return [];
        }
      } catch (error) {
          console.error('お知らせ情報の取得に失敗しました:', error);
          throw error;
      }
    },

/**
 * お知らせ情報を取得する関数
 * @param {string} newsId - お知らせID
 * @param {boolean} [admin=false] - 管理者フラグ。trueの場合、詳細データを含むレスポンスを返す。
 * @returns {Promise<Object>} ニュース情報のオブジェクト
 */
    async getNews(newsId, admin = false) {
      try {
        const response = await axios.get(`${BASE_URL}/${newsId}`);
        
        // デバッグ: レスポンス全体を確認
        // console.log('APIレスポンス:', response.data);

        // bodyが既にオブジェクトならJSON.parse不要
        const news = response.data.body;
        if (response.data.statusCode != 200) {
          console.error('取得に失敗しました:', news);
          throw new Error('お知らせ情報の取得に失敗しました');              
        }
        if (!news) {
          throw new Error('お知らせ情報が見つかりません');
        }

        // 必要なデータを抽出
        if (admin) {
          // 管理者用データ
          return {
            news_id: news.news_id,
            title: news.title,
            content: news.content,
            date: news.date,
            create_info: news.create_info,
            update_info: news.update_info,
          };
        } else {
          // 一般ユーザー用データ
          return {
            title: news.title,
            content: news.content,
            date: news.date,
          };
        }
      } catch (error) {
        console.error('お知らせ情報の取得に失敗しました:', error);
        throw error;
      }
    },

/**
   * お知らせ情報を更新する
   * @param {string} newsId お知らせID
   * @param {Object} updatedNewsData 更新データ
   * @returns {Promise<void>} 更新後のお知らせデータ
   */

    async patchNews(newsId, updatedNewsData) {
        try {
            const headers = tokenService.createHeaders()
            
            // console.log(`お知らせ情報を更新します`, updatedNewsData)
            // 必要なデータのみ抽出
            const { title, content, date } = updatedNewsData;
        
            // PATCH リクエスト
            const response = await axios.patch(`${BASE_URL}/${newsId}`, 
                JSON.stringify({
                    title: title,
                    content: content,
                    date: date
                  }), 
                  { headers }
                );
            if (response.data.statusCode != 200) {
              console.error('更新に失敗しました:');
              throw new Error('お知らせ情報の更新に失敗しました');              
            }
        } catch (error) {
            console.error(`お知らせ情報 (ID: ${newsId}) の更新に失敗しました:`, error);
            throw error;
        }
    },
    
  /**
   * お知らせ情報を削除する
   * @param {string} newsId お知らせID
   * @returns {Promise<void>}
   */
    async deleteNews(newsId) {
        try {
            const headers = tokenService.createHeaders()
            const response = await axios.delete(`${BASE_URL}/${newsId}`, { headers });
            if (response.data.statusCode != 200) {
              console.error('削除に失敗しました:');
              throw new Error('お知らせ情報の削除に失敗しました');              
            }
            console.log(`お知らせ情報 (ID: ${newsId}) を削除しました。`);
        } catch (error) {
            console.error(`お知らせ情報 (ID: ${newsId}) の削除に失敗しました:`, error);
            throw error;
        }
    },
};

export default newsService;