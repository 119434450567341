<template>
    <section id="add-treatment">
      <h2>診療内容を追加</h2>
      <form @submit.prevent="addTreatment(newTreatment)">
        <div class="form-group">
          <!-- 画像を追加 -->
          <label for="treatment_image">画像を追加</label>
          <input
            id="treatment_image"
            type="file"
            @change="onFileSelect"
            accept="image/*"
          />
          <!-- 治療カテゴリ名 -->
          <label for="treatment_category_name">治療カテゴリ名</label>
          <select
            id="treatment_category_name"
            v-model="newTreatment.treatment_category_name"
            @change="updateTreatmentCategory"
          >
            <option value="" disabled selected>未選択</option>
            <option value="一般内科">一般内科</option>
            <option value="皮膚科">皮膚科</option>
            <option value="泌尿器科">泌尿器科</option>
            <option value="検査・予防接種">検査・予防接種</option>
          </select>

          <!-- 診断名 -->
          <label for="treatment_name">病名</label>
          <input
            id="treatment_name"
            type="text"
            v-model="newTreatment.treatment_name"
            required
          />
  
          <!-- 症状説明 -->
          <label for="symptoms_description">症状説明</label>
          <textarea
            id="symptoms_description"
            v-model="newTreatment.symptoms_description"
            required
          ></textarea>
  
          <!-- ページタイトル -->
          <label for="title">ページタイトル</label>
          <input id="title" type="text" v-model="newTreatment.title" />
  
          <!-- ページ内容 -->
          <label for="content">ページ内容</label>
          <textarea id="content" v-model="newTreatment.content"></textarea>
          <button type="button" @click="addDetail" class="add-detail-btn">+ 詳細を追加</button>
          <!-- 詳細 -->
          <div v-for="(detail, index) in newTreatment.details" :key="index" class="form-group">
            <h3>詳細 {{ index + 1 }}</h3>
            <label for="sub_title">サブタイトル</label>
            <input
              id="sub_title"
              type="text"
              v-model="detail.sub_title"
              required
            />
            <label for="sub_content">サブコンテンツ</label>
            <textarea id="sub_content" v-model="detail.sub_content"></textarea>
          </div>
          <!-- 詳細削除 -->
          <div v-if="newTreatment.details.length > 0" :key="index" style="margin-bottom: 15px;" class="form-group">
            <button type="button" @click="deleteDetail" class="delete-detail-btn">- 詳細を削除</button>
          </div>
          <!-- テーブル情報 -->
          <div class="table-content">
            <label for="table_description">テーブル説明:</label>
            <input id="table_description" type="text" v-model="newTreatment.table_description" />

            <div v-if="newTreatment.table_description">
              <table>
                <thead>
                  <tr>
                    <th v-for="(header, index) in newTreatment.headers" :key="index">
                      <input type="text" v-model="newTreatment.headers[index]" />
                    </th>
                    <th style="border-color: transparent; background-color: transparent;">
                      <button type="button" @click="addColumn" class="add-detail-btn">列を追加</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in newTreatment.rows" :key="rowIndex">
                    <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                      <input type="text" v-model="newTreatment.rows[rowIndex][cellIndex]" />
                    </td>
                    <td v-if="rowIndex === 0" style="border-color: transparent; background-color: transparent;">
                      <button type="button" @click="removeColumn" :disabled="newTreatment.headers.length <= 1" class="delete-detail-btn">列を削除</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="margin: 1vw 1vw">
                <button type="button" @click="addRow" class="add-detail-btn">行を追加</button>
                <button type="button" @click="removeRow" :disabled="newTreatment.rows.length <= 1" class="delete-detail-btn">行を削除</button>
              </div>
            </div>
          </div>
        </div>
        <button type="submit">診療内容を追加</button>
      </form>
  
      <!-- SnackBarの表示 -->
      <SnackBar :message="snackBarMessage" />
    </section>
  </template>
  
  <script>
  import treatmentService from "@/services/treatmentService";
  import SnackBar from "@/components/SnackBar";
  
  export default {
    name: "AddTreatment",
    components: {
      SnackBar,
    },
    data() {
      return {
        newTreatment: {
          treatment_category: "",
          treatment_category_name: "",
          treatment_name: "",
          symptoms_description: "",
          title: "",
          content: "",
          details: [],
          table_description: "",
          headers: [" ", " "],
          rows: [[" ", " "]],
        },
        selectedImage: null, // 追加: 選択された画像ファイル
        snackBarMessage: "",
      };
    },
    methods: {
      // ファイル選択時に呼ばれるメソッド
      onFileSelect(event) {
        const file = event.target.files[0];
        if (file) {
          this.selectedImage = file;
        }
      },
      updateTreatmentCategory() {
        const categoryMap = {
          "一般内科": "general_internal_medicine",
          "皮膚科": "dermatology",
          "泌尿器科": "urology",
          "検査・予防接種": "exam_immunization",
        };
        this.newTreatment.treatment_category =
          categoryMap[this.newTreatment.treatment_category_name] || "";
      },
      addDetail() {
        this.newTreatment.details.push({ sub_title: "", sub_content: "" });
      },
      deleteDetail() {
          if (this.newTreatment.details.length > 0) {
              this.newTreatment.details.pop(); // 配列の最後の要素を削除
          } else {
              console.warn("削除する詳細がありません"); // 必要に応じて警告を表示
          }
      },
      addRow() {
        this.newTreatment.rows.push(Array(this.newTreatment.headers.length).fill(''));
      },
      removeRow() {
        if (this.newTreatment.rows.length > 1) {
          this.newTreatment.rows.pop();
        }
      },
      addColumn() {
        this.newTreatment.headers.push(' ');
        this.newTreatment.rows.forEach((row) => row.push(''));
      },
      removeColumn() {
        if (this.newTreatment.headers.length > 1) {
          this.newTreatment.headers.pop();
          this.newTreatment.rows.forEach((row) => row.pop());
        }
      },
      async addTreatment(newTreatment) {
        if (
          newTreatment.treatment_category &&
          newTreatment.treatment_category_name &&
          newTreatment.treatment_name &&
          newTreatment.symptoms_description
        ) {
          try {
            if (!newTreatment.table_description) {
              newTreatment.headers = null;
              newTreatment.rows = null;
            }
            const response = await treatmentService.postTreatment(newTreatment);

            // 画像が選択されている場合は画像をアップロード
            if (this.selectedImage) {
              try{
                // console.log("response:", response);
                const treatmentId = response.id; // サーバーから取得
                // console.log("id:", treatmentId);
                const filename = this.selectedImage.name;

                await treatmentService.putTreatmentImage(
                  newTreatment.treatment_category,
                  treatmentId,
                  filename,
                  this.selectedImage
                );
              } catch (error) {
                console.error("画像の追加に失敗しました:", error);
              }
            }
            // フォームをリセット
            this.newTreatment = {
              treatment_category: "",
              treatment_category_name: "",
              treatment_name: "",
              symptoms_description: "",
              title: "",
              content: "",
              details: [],
              table_description: "",
              headers: ["", ""],
              rows: [["", ""]],
            };
            this.selectedImage = null; // ファイル選択をリセット
            this.snackBarMessage = "診療内容を新規作成しました";
          } catch (error) {
            console.error("診療内容の追加に失敗しました:", error);
          } finally {
            this.snackBarMessage = '';
            this.$emit('update-treatments');
          }
        } else {
          alert("必須項目を入力してください。");
        }
      },
    },
  };
  </script>
  
  <style scoped>
  #add-treatment {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  #add-treatment form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }
  
  label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  input,
  textarea, select {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  textarea {
    resize: vertical;
    height: 150px;
  }
  
  button {
    width: 100%;
    font-size: 16px;
    background-color: rgb(0, 148, 255);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: none; /* 均等幅を解除 */
    padding: 6px 10px; /* パディングを調整 */
    margin: 5px; /* ボタン間の余白 */
    font-size: 14px; /* ボタンの文字サイズを調整 */
  }
  
  button:hover {
    background-color: rgb(2, 95, 161);
  }
  
  button:focus {
    outline: none;
  }
  
  input:focus, 
  textarea:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }

  .add-detail-btn {
    background-color: #4CAF50;
    width: fit-content;
  }

  .add-detail-btn:hover {
    background-color: #45a049;
  }

  .delete-detail-btn{
    background-color: rgb(255, 17, 0);
    width: fit-content;
  }

  .delete-detail-btn:hover {
    background-color: rgb(156, 13, 3);
  }

  .table-content {
    margin-top: 15px;
  }

  table{
    width: 100%;
    border-collapse: collapse;
    text-indent: initial;
    table-layout: fixed;
    background-color: #f4f4f4;;
  }

  table th,table td{
    color: #505050;
    border:solid 1px #ddd;
    text-align: center;
    padding: .5em .75em;
    vertical-align: top;
    display: table-cell;
    unicode-bidi: isolate;
  }


  table th input, table td input{
    border-color: transparent;
    width: 100%;
    background-color: transparent;
  }

  /* 一番左の列のセルにスタイルを適用 */
  table td:first-child, table th:first-child {
    background: #f7f7f7;
    border: 1px solid #ddd;
    color: #505050;
    text-align: center;
    white-space: nowrap; /* テキストを折り返さない */
    font-weight: bolder;
  }
  </style>
  