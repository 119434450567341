<template>
  <nav class="nav-menu">
    <div class="logo-menu">
      <!-- ロゴ部分 -->
      <div class="page_navbar-logo">
        <router-link to="/">
          <img src="@/assets/icon/aozora_logo_image.png" alt="Logo" class="logo-image" />
        </router-link>
      </div>

      <!-- メニュー部分 -->
      <div class="menu-content">
        <ul class="menu-list">
          <li
            class="menu-item drop-menu"
            :class="{ open: activeMenu === 'about' }"
          >
            <button @click="toggleMenu('about')">クリニックについて</button>
            <ul class="drop-menu-list drop-menu-sizing-max">
              <li class="drop-menu-item" @click="closeMenu()">
                <router-link :to="{ path: '/greeting'}">院長挨拶</router-link>
              </li>
              <li class="drop-menu-item" @click="closeMenu()"> <!-- すでにそのページにいる -->
                <router-link :to="{ path: '/', hash: '#news-list' }">お知らせ</router-link> <!-- 遷移してから移動 -->
              </li>
              <li class="drop-menu-item" @click="closeMenu()"> 
                <router-link :to="{ path: '/', hash: '#clinic-introduction' }">院内紹介</router-link>
              </li>
              <li class="drop-menu-item" @click="closeMenu()">
                <router-link :to="{ path: '/', hash: '#access' }">アクセス</router-link>
              </li>
            </ul>
          </li>
          <li
            class="menu-item drop-menu"
            :class="{ open: activeMenu === 'treatment' }"
          >
            <button @click="toggleMenu('treatment')">診療内容</button>
            <ul class="drop-menu-list drop-menu-sizing">
              <li class="drop-menu-item" @click="navigateToTreatmentList('general_internal_medicine')">
                <a>一般内科</a>
              </li>
              <li class="drop-menu-item" @click="navigateToTreatmentList('dermatology')">
                <a>皮膚科</a>
              </li>
              <li class="drop-menu-item" @click="navigateToTreatmentList('urology')">
                <a>泌尿器科</a>
              </li>
              <li class="drop-menu-item" @click="navigateToTreatmentList('exam_immunization')">
                <a>検査・予防接種</a>
              </li>
            </ul>
          </li>
          <li
            class="menu-item drop-menu"
            :class="{ open: activeMenu === 'recruitment' }"
          >
            <button @click="toggleMenu('recruitment')">採用情報</button>
            <ul class="drop-menu-list drop-menu-sizing">
              <li class="drop-menu-item" @click="closeMenu()">
                <router-link :to="{ path: '/recruitment', hash: '#registered_nurse' }">看護師</router-link>
              </li>
              <li class="drop-menu-item" @click="closeMenu()">
                <router-link :to="{ path: '/recruitment', hash: '#medical_office_worker' }">医療事務</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- 右側の住所と電話番号 -->
    <div class="page_navbar-info-content">
      <div class="page_navbar-info">
        <div class="page_navbar-logo">
          <a
            href="https://www.instagram.com/aozora_clinic?igsh=MTlua3l4dWt4YWVybw=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/icon/insta_icon.png" alt="Logo" class="sns-image" />
          </a>
        </div>
        <div class="page_navbar-access">
          <p class="address">〒939-8057</p>
          <p class="address">富山県富山市堀141</p>
          <a href="tel:0764204411">
            <p class="phone">TEL: 076-420-4411</p>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PageNavigation',
  data() {
    return {
      activeMenu: null, // 現在開いているメニュー
    };
  },
  methods: {
    toggleMenu(menu) {
      // 同じメニューがクリックされた場合は閉じる
      this.activeMenu = this.activeMenu === menu ? null : menu;
    },
    navigateToTreatmentList(treatmentCategory) {
      // 診療内容のページに遷移
      this.$router.push(`/treatment/${treatmentCategory}`);
      this.closeMenu();
    },
    handleClickOutside(event) {
      // メニュー外をクリックした場合にメニューを閉じる
      const menu = this.$el;
      if (!menu.contains(event.target)) {
        this.closeMenu();
      }
    },
    closeMenu() {
      // メニューを閉じる処理
      this.activeMenu = null;
    }
  },
  mounted() {
    // ドキュメント全体でクリックイベントをリスンして、メニュー外のクリックで閉じる
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    // コンポーネントが破棄される際にイベントリスナーを削除
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>


<style scoped>
/* ナビゲーションメニュー */
.nav-menu {
  background-color: rgba(243, 241, 241, 0.836); /* メニューの背景色 */
  color: #696969; /* メニューテキストの色 */
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 要素間を均等に配置 */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 10vw;
  max-height: 120px;
}

.logo-menu {
  display: flex;
  align-items: center;
  width: 100%;
}

.page_navbar-logo {
  display: flex;
  align-items: center;
  text-align: left;
}

.logo-image {
  width: 80px;
  height: auto;
  cursor: pointer;
  margin:0 2vw;
}

.sns-image {
  width: 30px;
  height: auto;
  cursor: pointer;
  margin-left: 2vw;
}

.menu-content {
  flex: 1; /* メニューを中央揃えするためのスペースを確保 */
  display: flex;
  justify-content: center;
}

.menu-list {
  display: flex;
  justify-content: center; /* メニューアイテムを中央揃えに */
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-item {
  background-color: rgba(243, 241, 241, 0);  /* メニューアイテムの背景色 */
  border-left: 1px solid #40d5ff; /* メニューアイテムの左ボーダー */
  position: relative;
  transition: background-color .8s; /* 背景色の変化をアニメーション化 */
}

.menu-item:last-child {
  border-right: 1px solid #40d5ff; /* 最後のメニューアイテムの右ボーダー */
}

/* メニューアイテムのクリック時のスタイル */
.menu-item.drop-menu.open .drop-menu-list {
  transform: scaleY(1); /* ドロップダウンメニューを表示 */
}

.menu-item.drop-menu .drop-menu-list {
  transform: scaleY(0); /* ドロップダウンメニューを非表示 */
  transform-origin: center top;
  transition: transform 0.3s ease-in-out;
}

.drop-menu-sizing-auto {
  width: auto;
} 

.drop-menu-sizing-max {
  width: 100%;
}

.menu-item:hover {
  background-color: #40d5ff; /* メニューアイテムのホバー時の背景色 */
  transition: background-color .3s; /* 背景色の変化をアニメーション化 */
}

.drop-menu-item:hover {
  background-color: #696969; /* メニューアイテムのホバー時の背景色 */
  transition: background-color .3s; /* 背景色の変化をアニメーション化 */
}

.menu-item a {
  align-items: center;
  display: flex;
  height: 50px;
  text-decoration: none; /* リンクの下線を非表示 */
  width: max-content;
  padding: 0 2vw;
  font-size: min(3vw, 20px);
  font-weight: bolder;
}

.menu-item button {
  align-items: center;
  color:black;
  display: flex;
  height: 50px;
  justify-content: center;
  text-decoration: none; /* リンクの下線を非表示 */
  width: max-content;
  padding: 0 15px;
  font-size: min(1.5vw, 20px);
  font-weight: bolder;
  /* 背景色を無色に */
  background: transparent;
  /* 枠線を消す */
  border: none;
  /* クリックした際に枠線をnone消す */
  outline: none;
  /* 影を消す */
  box-shadow: none;
}

/* ドロップダウンメニュー */
.drop-menu {
  position: relative;
}

.drop-menu-list {
  left: 0;
  position: absolute;
  top: 100%;
  transform: scaleY(0); /* ドロップダウンメニューの非表示 */
  transform-origin: center top; /* 変形を適応する基準を設定 */
  transition: transform .3s; /* 表示の変化をアニメーション化 */
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
}

.drop-menu-item {
  background-color: #fff; /* ドロップダウンメニューの背景色 */
  padding: 10px;
  text-align: center;
  width: auto;
  color: #696969;
}

.drop-menu-item a {
  color: #696969;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.drop-menu-item:hover a {
  color: #fff; /* メニューアイテムのホバー時の文字色 */
}

.page_navbar-info-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
}

.page_navbar-info {
  text-align: right;
  display:flex;
}

.page_navbar-access {
  display: block;
  margin: 0 30px;
  width: max-content;
}

.address,
.phone {
  text-align: left;
  margin: 0;
  color: black;
  margin-top: 10px;
}

/* ナビゲーションバーが固定された状態の余白を確保 */
body {
  padding-top: 60px; /* ナビゲーションバーの高さ分だけ余白を追加 */
}

@media (max-width: 768px) {
  .menu-item button {
    font-size: 3.1vw;
  }
  .nav-menu {
    display: block;
    height: 30vw;
  }

  .logo-image {
    width: 50px;
    height: auto;
    cursor: pointer;
    margin-right: 2.5vw;
    margin-left: 0;
  }

  .menu-content {
    display: flex;
    justify-content: flex-start;
  }

  .page_navbar-info-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }

  .address,
  .phone {
    font-size: 3vw;
    margin-top: 0;
  }
}
</style>
