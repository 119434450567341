<template>
    <div class="clinic-schedule-block">
        <p>月～金曜日：午前 8:30～12:30 ／ 午後 15:00～18:30</p>
        <p>土曜日：午前 8:30～12:00 ／ 午後 13:00～15:00</p>

        <p>※ 木曜・日曜・祝日は休診となります</p>

        <div class="clinic-schedule">
            <table class="design14">
                <thead>
                <tr>
                    <th class="caption"></th>
                    <th class="caption" v-for="(day, index) in days" :key="day"
                        :class="{ 'today': index === todayIndex }">
                    {{ day }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="caption">午前</td>
                    <td v-for="(day, index) in schedule" :key="'morning-' + index"
                        :class="{ 'open': day.morning === '○', 'closed': day.morning === '✕' }">
                    {{ day.morning }}
                    </td>
                </tr>
                <tr>
                    <td class="caption">午後</td>
                    <td v-for="(day, index) in schedule" :key="'afternoon-' + index"
                        :class="{ 'open': day.afternoon === '○', 'closed': day.afternoon === '✕' }">
                    {{ day.afternoon }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ClinicSchedule",
        data() {
            return {
            days: ["月", "火", "水", "木", "金", "土", "日"],
            schedule: [
                { morning: "○", afternoon: "○" }, // 月
                { morning: "○", afternoon: "○" }, // 火
                { morning: "○", afternoon: "○" }, // 水
                { morning: "休", afternoon: "休" }, // 木
                { morning: "○", afternoon: "○" }, // 金
                { morning: "○", afternoon: "△" }, // 土
                { morning: "休", afternoon: "休" }, // 日
            ],
            todayIndex: (new Date().getDay() + 6) % 7, // 月曜日を0にするために調整
            };
        },
    };
</script>

<style scoped>
    p {
        margin: 0.4vw;
    }
    .clinic-schedule {
        display: flex;
        justify-content: center;
    }

    .clinic-schedule-block p{
        font-size: 1em;
        color: black;
    }

    .clinic-schedule-block {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    /* グラデーションを使用したスタイル */
    .design14 {
        max-width: 600px;
        width: 80%;
        text-align: center;
        border-collapse: collapse;
        border-spacing: 0;
        background: linear-gradient(to bottom right, #40d5ff, #00aad9);
        color: #ffffff;
        margin-bottom: 1vw;
    }

    .design14 th,
    .design14 td {
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-right: 1vw;
        padding-left: 1vw;
        border: solid 1px #d0d0d0;
    }

    /* 一番左の列のセルにスタイルを適用 */
    .design14 td:first-child, .design14 th:first-child {
    background-color: #f7f7f7; /* 背景色をグレーに */
    color: #333; /* 文字色を濃いグレーに */
    width: auto; /* 幅を内容に合わせて自動調整 */
    white-space: nowrap; /* テキストを折り返さない */
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    }

    /* 一番上の行のセルにスタイルを適用 */
    .design14 th {
    background-color: #f7f7f7; /* 背景色を少し薄いグレーに */
    color: #444; /* 文字色を少し濃いグレーに */
    }

    /* 一番左上のセルにスタイルを適用 */
    .design14 th:first-child {
    background-color: #d0d0d0; /* 背景色をさらに薄いグレーに */
    color: #555; /* 文字色をさらに濃いグレーに */
    }

    .design14 >>> .caption {
        font-weight: bold;
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 0 0 16px rgba(255, 255, 255, 0.5);
        font-size: min(2.8vw, 20px);
    }

    /* 今日の曜日のスタイルのみ変更 */
    .design14 .today {
        background-color: #1757e0;
        color: #ffffff;
        font-weight: bold;
    }

    /* 開院と閉院のスタイル */
    .design14 td.open {
        color: #ffffff;
    }

    .design14 td.closed {
        color: #fff;
    }

    @media (max-width: 768px) {

        .design14 {
            line-height: 2;
        }
        .design14 td{
            font-size: min(3.4vw, 23px);
        }

    }
</style>
