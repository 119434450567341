<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal">
      <div class="close-btn">
        <button @click="close"><span class="round_btn"></span></button>
      </div>
      <h2>編集モード</h2>
      <div class="info-row">
        <p><small>作成者: {{ localTreatmentData.create_info.user.user_name }}</small></p>
        <p><small>作成日: {{ formatDate(localTreatmentData.create_info.datetime) }}</small></p>
        <p><small>最終更新者: {{ localTreatmentData.update_info.user.user_name }}</small></p>
        <p><small>最終更新日: {{ formatDate(localTreatmentData.update_info.datetime) }}</small></p>
      </div>

      <div class="form-group">
        <!-- Presigned URL を表示 -->
        <div class="image-container" v-if="localTreatmentData.image_path">
          <label>現在の画像:</label>
          <img :src="localTreatmentData.image_path" alt="Treatment Image" class="treatment-image" />
        </div>
        <!-- 画像を追加 -->
        <label for="treatment_image">画像を変更:</label>
        <input
          id="treatment_image"
          type="file"
          @change="onFileSelect"
          accept="image/*"
        />
      </div>
      <div class="form-group">
        <label for="treatment_number">カテゴリ内の表示順序:</label>
        <input v-model="localTreatmentData.treatment_number" type="number" id="treatment_number" :placeholder="localTreatmentData.treatment_number" required />
      </div>
      <div class="form-group">
        <label for="treatment_name">病名:</label>
        <input v-model="localTreatmentData.treatment_name" type="text" id="treatment_name" :placeholder="localTreatmentData.treatment_name" required />
      </div>
      <div class="form-group">
        <label for="symptoms_description">症状説明:</label>
        <textarea v-model="localTreatmentData.symptoms_description" id="symptoms_description" required></textarea>
      </div>
      <div class="form-group">
        <label for="title">ページタイトル:</label>
        <input v-model="localTreatmentData.title" type="text" id="title" :placeholder="localTreatmentData.title" required />
      </div>
      <div class="form-group">
        <label for="content">ページ内容:</label>
        <textarea v-model="localTreatmentData.content" id="content" required></textarea>
      </div>
      <button type="button" @click="addDetail" class="add-detail-btn">+ 詳細を追加</button>
      <div class="form-group" v-if="localTreatmentData.details.length > 0">
        <div v-for="(detail, index) in localTreatmentData.details" :key="index">
          <div class="form-group">
            <label for="sub_title">サブタイトル:</label>
            <input v-model="detail.sub_title" type="text" id="sub_title" :placeholder="detail.sub_title" required />
          </div>
          <div class="form-group">
            <label for="sub_content">サブコンテンツ:</label>
            <textarea v-model="detail.sub_content" id="sub_content" required></textarea>
          </div>
        </div>
        <button type="button" @click="deleteDetail" class="delete-detail-btn">- 詳細を削除</button>
      </div>
      <!-- テーブル情報 -->
      <div class="form-group">
        <div class="form-group">
          <label for="table_description">テーブル説明:</label>
          <input id="table_description" type="text" v-model="localTreatmentData.table_description" />
        </div>
        <div v-if="localTreatmentData.table_description">
          <table>
            <thead>
              <tr>
                <th v-for="(header, index) in localTreatmentData.headers" :key="index">
                  <input type="text" v-model="localTreatmentData.headers[index]" />
                </th>
                <th style="border-color: transparent; background-color: transparent;">
                  <button type="button" @click="addColumn" class="add-detail-btn">列を追加</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in localTreatmentData.rows" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                  <input type="text" v-model="localTreatmentData.rows[rowIndex][cellIndex]" />
                </td>
                <td v-if="rowIndex === 0" style="border-color: transparent; background-color: transparent;">
                  <button type="button" @click="removeColumn" :disabled="localTreatmentData.headers.length <= 1" class="delete-detail-btn">列を削除</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="margin: 1vw 1vw">
            <button type="button" @click="addRow" class="add-detail-btn">行を追加</button>
            <button type="button" @click="removeRow" :disabled="localTreatmentData.rows.length <= 1" class="delete-detail-btn">行を削除</button>
          </div>
        </div>
      </div>
      <div class="form-button">
        <button @click="edit" class="patch-btn">変更</button>
        <button @click="confirmDelete" class="delete-btn">削除</button>
      </div>
      <!-- ConfirmModalを使用して削除確認 -->
      <ConfirmModal 
        :visible="isConfirmModalVisible" 
        :message="modalMessage" 
        @confirm="del" 
        @cancel="cancelDelete" 
      />
    </div>

  </div>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal';
import treatmentService from "@/services/treatmentService";
import { formatDate } from '@/utils/utils';
export default {
  name: 'TreatmentEditModal',
  components: {
    ConfirmModal,
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    treatmentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Create a local copy of the treatmentData prop
      localTreatmentData: { ...this.treatmentData },
      selectedImage: null,
      isConfirmModalVisible: false,
      modalMessage: '',
    };
  },
  methods: {
    formatDate,
    onFileSelect(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
      }
    },
    async edit() {
      try {
        console.log(this.localTreatmentData);
        if (!this.localTreatmentData.table_description) {
          this.localTreatmentData.headers = null;
          this.localTreatmentData.rows = null;
        }
        await treatmentService.patchTreatment(this.localTreatmentData.treatment_category, this.localTreatmentData.treatment_id, this.localTreatmentData); 
        // 画像が選択されている場合は画像をアップロード
        if (this.selectedImage) {
          try{
            const filename = this.selectedImage.name;
            await treatmentService.putTreatmentImage(
              this.localTreatmentData.treatment_category,
              this.localTreatmentData.treatment_id,
              filename,
              this.selectedImage
            );
          } catch (error) {
            console.error("画像の変更に失敗しました:", error);
          }
        }
      } catch (error) {
        console.error('APIエラー:', error);
      } finally {
        this.selectedImage = null; // ファイル選択をリセット
        this.$emit('edit');
      }
    },
    confirmDelete() {
      this.modalMessage = `「${this.localTreatmentData.treatment_name}」を削除しますか？`; // 削除するニュースのタイトルを表示
      this.isConfirmModalVisible = true;
    },
    cancelDelete() {
      this.isConfirmModalVisible = false;
    },
    async del() {
      try {
        await treatmentService.deleteTreatment(this.localTreatmentData.treatment_category, this.localTreatmentData.treatment_id); 
      } catch (error) {
        console.error('APIエラー:', error);
      } finally {
        this.isConfirmModalVisible = false;
        this.$emit('del');
      }
    },
    close() {
      this.$emit('close');
    },
    addDetail() {
      this.localTreatmentData.details.push({ sub_title: "", sub_content: "" });
      },
    deleteDetail() {
        if (this.localTreatmentData.details.length > 0) {
          this.localTreatmentData.details.pop(); // 配列の最後の要素を削除
        } else {
            console.warn("削除する詳細がありません"); // 必要に応じて警告を表示
        }
    },
    addRow() {
      if (this.localTreatmentData.headers.length == 0) {
        this.localTreatmentData.headers = ["", ""];
        this.localTreatmentData.rows = [["", ""]];
      } else {
        this.localTreatmentData.rows.push(Array(this.localTreatmentData.headers.length).fill(''));
      }
      },
    removeRow() {
      if (this.localTreatmentData.rows.length > 1) {
        this.localTreatmentData.rows.pop();
      }
    },
    addColumn() {
      this.localTreatmentData.headers.push(' ');
      this.localTreatmentData.rows.forEach((row) => row.push(''));
    },
    removeColumn() {
      if (this.localTreatmentData.headers.length > 1) {
        this.localTreatmentData.headers.pop();
        this.localTreatmentData.rows.forEach((row) => row.pop());
      }
    },
  },
  watch: {
    // Watch for changes in treatmentData and update local copy
    treatmentData: {
      handler(newVal) {
        this.localTreatmentData = { ...newVal };
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-btn {
  text-align: right;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  max-height: 80vh; /* モーダルの最大高さを設定 */
  overflow-y: auto; /* 縦方向にスクロール可能 */
  width: 80%;
}

.modal .info-row {
  display: flex;
  gap: 15px; /* 各要素の間にスペースを確保 */
  flex-wrap: wrap; /* 横並びでも、画面が狭ければ折り返し */
  margin-bottom: 30px; /* 上部にスペースを追加 */
}

.treatment-image {
  width: 35%;
  height: auto;
  border-radius: 8px;
  margin: 10px;
  margin-top: 0;
}

button {
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  flex: none; /* 均等幅を解除 */
  padding: 6px 10px; /* パディングを調整 */
  margin: 5px; /* ボタン間の余白 */
  font-size: 14px; /* ボタンの文字サイズを調整 */
  width: auto; /* ボタンの幅をコンテンツに合わせる */
  max-width: 150px; /* 必要なら最大幅を設定 */
}

button.add-detail-btn {
  background-color: #4caf50;
}

button.add-detail-btn:hover {
  background-color: #45a049;
}
  
button:focus {
  outline: none;
}

button.delete-detail-btn{
  background-color: rgb(255, 17, 0);
}

button.delete-detail-btn:hover {
  background-color: rgb(156, 13, 3);
}

.round_btn {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #333;
  border-radius: 50%;
  background: #fff;
  transition: all 1s; /* アニメーション設定 */
}
 
.round_btn::before, .round_btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 22px;
  background: #333;
}
 
.round_btn::before {
  transform: translate(-50%,-50%) rotate(45deg);
}
 
.round_btn::after {
  transform: translate(-50%,-50%) rotate(-45deg);
}
 
.round_btn:hover { /* マウスオーバー時のスタイル */
    transform: rotate(360deg);
}

table{
  width: 100%;
  border-collapse: collapse;
  text-indent: initial;
  table-layout: fixed;
  background-color: #fff;
}

table th,table td{
  color: #505050;
  border:solid 1px #ddd;
  text-align: center;
  padding: .5em .75em;
  vertical-align: top;
  display: table-cell;
  unicode-bidi: isolate;
}

table th input, table td input{
  border-color: transparent;
  width: 100%;
  background-color: transparent;
}

/* 一番左の列のセルにスタイルを適用 */
table td:first-child, table th:first-child {
  background: #f7f7f7;
  border: 1px solid #ddd;
  color: #505050;
  text-align: center;
  white-space: nowrap; /* テキストを折り返さない */
  font-weight: bolder;
}

</style>
