<template>
    <div class="clinic-introduction">
        <div class="img">
            <img class="bgImg" src="@/assets/images/clinic_1.jpg" alt="">
        </div>
        <div class="description">
            <h3><span class="color-blue">青空クリニック</span>へようこそ</h3>
            <br/>
            <p>当クリニックは、地域の皆様に寄り添う“<span class="color-blue">地域密着型</span>”クリニックです。</p>
            <p>一般的な内科診療に加え、生活習慣病、アレルギー疾患など、幅広い診療に対応しています。</p>
            <br/>
            <p>また、訪問診療や健康診断、予防接種など、地域の皆様のニーズに合わせた医療サービスを提供しています。</p>
            <br/>
            <p>小さなお子様からご高齢の方まで、ご家族皆様の健康をサポートいたします。</p>
        </div>

        <div class="img">
            <img class="bgImg" src="@/assets/images/clinic_4.jpg" alt="">
        </div>
        <div class="description">
            <p>待合室は、広々とした<span class="color-blue">青空</span>をイメージしたデザインで、リラックスできる空間を演出しています。</p>
            <br/>
            <p>感染対策にも力を入れており、定期的な換気や消毒、空間除菌など、清潔な環境を維持しています。</p>
            <p>また、患者様同士の距離を確保するため、座席の間に仕切りを設けています。</p>
            <br/>
            <p>お子様連れの方も安心してご来院いただけるよう、畳コーナーを用意しています。</p>
        </div>

        <div class="img">
            <img class="bgImg" src="@/assets/images/clinic_5.jpg" alt="">
        </div>
        <div class="description" style="margin-bottom: 0;">
            <p>診療は、患者様一人ひとりと向き合い、丁寧な説明と的確な診断を心がけています。</p>
            <p>電子カルテや検査機器を導入し、迅速かつ効率的な診療を提供しています。</p>
            <br/>
            <p>地域の皆様の健康を支えるパートナーとして、これからも質の高い医療を提供してまいります。</p>
            <p>どんな些細なことでも、お気軽にご相談ください。</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ClinicIntroduction",
        data() {
            return {};
        },
    };
</script>

<style scoped>
.clinic-introduction {
    padding: 30px 10px;
    height: auto;
    text-align: center;
}

.clinic-introduction h3 {
    font-size: clamp(21px, 2.08vw, 32px);
    font-weight:900;
}

.clinic-introduction p {
    font-size: clamp(15px, 1.18vw, 19px);
}

.img .bgImg {
  /* clip-path: polygon(0% 20%, 100% 20%, 100% 80%, 0 80%); */
  width: 60%;
  object-fit: cover;
}

.description {
  margin:15vh 0;
  color: black;
  padding: 0px;
  text-align: center;
}

.overlay-string-on-image-mainFloor {
  position: relative;
  bottom: 30vw;
  left: 50%;
  width: fit-content;
  color: black;
  padding: 0px;
  text-align: center;
}

.overlay-string-on-image-other {
  position: relative;
  bottom: 33vw;
  left: 50%;
  width: fit-content;
  color: black;
  padding: 0px;
  text-align: center;
}

.color-blue {
    color: #1757e0; 
    font-weight: bold;
}

/* メディアクエリでスマホ表示を最適化 */
@media (max-width: 768px) {
  .description p{
    letter-spacing: 1px;
    line-height: 1.5;
  }

  .img .bgImg {
    /* clip-path: polygon(0% 20%, 100% 20%, 100% 80%, 0 80%); */
    width: 100%;
    object-fit: cover;
  }
}
</style>